<template>
  <v-container fluid full  v-if="allProducts.length >= 1">

      <!--Product Session-->
      <v-container class="productContainer hidden-sm-and-down">
        <v-row wrap>
          <v-col cols="4">  <h3>Recent Products</h3> </v-col>
          <v-col cols="8"> 
            <v-text-field
                    solo
                    rounded
                    dense
                    v-model="search"
                    label="Search medication.. "
                    append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
      
        <v-row>
          <template v-if="isAuth && userRole">
            <v-col v-for="product in filteredList" :key="product.id" md="3">
              <v-sheet
                height="400"
                style="background-color:#FAFAFA;border-radius:10px;"
              >
                 
                <div style="background-color:#FFFFFF;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:210px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                
                <div class="mt-3 ml-3" align="center"  style="background-color:#FAFAFA">
                  <p style="height:45px">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  <!-- <div style="padding:10px; font-size:12px;">
                    {{ getProductContent(product) }}
                  </div> -->
                  <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5>
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else-if="isAuth && retailerRole">
            <v-col v-for="product in retailerProducts.slice(0,12)" :key="product.id" md="3">
              <v-sheet
                height="400"
                style="background-color:#FAFAFA;border-radius:10px;"
              >
                 
                <div style="background-color:#FFFFFF;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:210px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                
                <div class="mt-3 ml-3" align="center"  style="background-color:#FAFAFA">
                  <p style="height:45px">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  <!-- <div style="padding:10px; font-size:12px;">
                    {{ getProductContent(product) }}
                  </div> -->
                  <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5>
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else-if="isAuth && distributorRole">
            <v-col v-for="product in distributorProducts.slice(0,12)" :key="product.id" md="3">
              <v-sheet
                height="400"
                style="background-color:#FAFAFA;border-radius:10px;"
              >
                 
                <div style="background-color:#FFFFFF;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:210px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                
                <div class="mt-3 ml-3" align="center"  style="background-color:#FAFAFA">
                  <p style="height:45px">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  <!-- <div style="padding:10px; font-size:12px;">
                    {{ getProductContent(product) }}
                  </div> -->
                  <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5>
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else>
            <v-col v-for="product in filteredList" :key="product.id" md="3">
              <v-sheet
                height="400"
                style="background-color:#FAFAFA;border-radius:10px;"
              >
                 
                <div style="background-color:#FFFFFF;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:210px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                
                <div class="mt-3 ml-3" align="center"  style="background-color:#FAFAFA">
                  <p style="height:45px">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  <!-- <div style="padding:10px; font-size:12px;">
                    {{ getProductContent(product) }}
                  </div> -->
                  <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5>
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>
        </v-row>
      </v-container>

      <v-container class="productContainerSm hidden-md-and-up">
        <v-row wrap>
          <v-col cols="12">  <h3>Recent Products</h3> </v-col>
          <v-col cols="12"> 
            <v-text-field
                    solo
                    rounded
                    dense
                    v-model="search"
                    label="Search medication.. "
                    append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <template v-if="isAuth && userRole">
            <v-col v-for="product in filteredList" :key="product.id" cols="6">
              <v-sheet
                height="340"
                style="background-color:#FAFAFA;border-radius:10px;"
              >
                <v-img
                  contain
                  class="productImg"
                  height="130px"
                  width="100%"
                  :src="product.productImagePath"
                  :to="'/product-detail/' + product.productId"
                  style="background-color:#FFFFFF;padding:25px !important;"
                />
                <div class="mt-3 ml-3" align="center"  style="background-color:#FAFAFA">
                  <p style="height:40px;font-size:14px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  <!-- <div style="padding:10px; font-size:12px;">
                    {{ getProductContent(product) }}
                  </div> -->
                  <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5>
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else-if="isAuth && retailerRole">
            <v-col v-for="product in retailerProducts.slice(0,12)" :key="product.id" cols="6">
              <v-sheet
                height="340"
                style="background-color:#FAFAFA;border-radius:10px;"
              >
                <v-img
                  contain
                  class="productImg"
                  height="130px"
                  width="100%"
                  :src="product.productImagePath"
                  :to="'/product-detail/' + product.productId"
                  style="background-color:#FFFFFF;padding:25px !important;"
                />
                <div class="mt-3 ml-3" align="center"  style="background-color:#FAFAFA">
                  <p style="height:40px;font-size:14px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  <!-- <div style="padding:10px; font-size:12px;">
                    {{ getProductContent(product) }}
                  </div> -->
                  <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5>
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else-if="isAuth && distributorRole">
            <v-col v-for="product in distributorProducts.slice(0,12)" :key="product.id" cols="6">
             <v-sheet
                height="340"
                style="background-color:#FAFAFA;border-radius:10px;"
              >
                <v-img
                  contain
                  class="productImg"
                  height="130px"
                  width="100%"
                  :src="product.productImagePath"
                  :to="'/product-detail/' + product.productId"
                  style="background-color:#FFFFFF;padding:25px !important;"
                />
                <div class="mt-3 ml-3" align="center"  style="background-color:#FAFAFA">
                  <p style="height:40px;font-size:14px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  <!-- <div style="padding:10px; font-size:12px;">
                    {{ getProductContent(product) }}
                  </div> -->
                  <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5>
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else>
            <v-col v-for="product in filteredList" :key="product.id" cols="6">
              <v-sheet
                height="340"
                style="background-color:#FAFAFA;border-radius:10px;"
              >
                <v-img
                  contain
                  class="productImg"
                  height="130px"
                  width="100%"
                  :src="product.productImagePath"
                  :to="'/product-detail/' + product.productId"
                  style="background-color:#FFFFFF;padding:25px !important;"
                />
                <div class="mt-3 ml-3" align="center"  style="background-color:#FAFAFA">
                  <p style="height:40px;font-size:14px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  <!-- <div style="padding:10px; font-size:12px;">
                    {{ getProductContent(product) }}
                  </div> -->
                  <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5>
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>
        </v-row>
      </v-container>

    
  </v-container>
</template>

<script>
export default {
  name: "SearchProducts",

  created() {
    this.isAuth = this.$auth.isAuthenticated();
    this.setAuthenticatedUser();
    this.getProducts();
  },

  computed: {
    filteredList() {
      return this.productLists.filter(product => {
        return product.name.toLowerCase().includes(this.$route.query.search.toLowerCase())
      })
    }
  },

  data: () => ({
    allProducts: [],
    productLists: [],
    retailerProducts:[],
    distributorProducts:[],
    search: '',
    systemSetting: {
      systemCurrency: "₦",
    },
    isAuth: null,
    userId:"",
    userRole:null,
    manufactureRole:null,
    distributorRole:null,
    retailerRole:null,

  }),
  methods: {
    ///////Truncate a text based on the number of characters//////
    getProductContent(product) {
      let body = this.stripTags(product.description);

      return body.length > 40 ? body.substring(0, 40) + "..." : body;
    },

    stripTags(text) {
      return text.replace(/(<([^>]+)>)/gi, "");
    },

    setAuthenticatedUser() {
      this.userId = localStorage.getItem("userId");
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + this.userId)
        .then((response) => {
          if (response.data.data.Role.name == "User") {
            this.userRole = true;
          }

          if (response.data.data.Role.name == "Manufacturer") {
            this.manufactureRole = true;
          }

          if (response.data.data.Role.name == "Distributor") {
            this.distributorRole = true;
          }

          if (response.data.data.Role.name == "Retailer") {
            this.retailerRole = true;
          }

        });
    },

    getProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          this.allProducts = response.data.data;
          response.data.data.forEach(element => {
            if(element.User.Role.name == "Retailer"){
              this.productLists.push(element);
            }

            if(element.User.Role.name == "Distributor"){
              this.retailerProducts.push(element);
            }

            if(element.User.Role.name == "Manufacturer"){
              this.distributorProducts.push(element);
            }

          });
        });
    },
  },
};
</script>

<style scoped>
.priceStyle {
  width: 90px;
  height: auto;
  font-size: 12px;
  background: linear-gradient(192.88deg, #111212 13.53%, #72bd61 90.69%);
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.buttonStyle {
  margin-top: 10px;
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 10px;
}

.productContainer {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 50px;
}

.productContainerSm {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px;
}

.productImg {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}
</style>
